import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../../components/Layout';

import './index.scss';

const PrivacyPolicyPage = () => (
  <Layout>
    <Helmet>
      <title>プライバシーポリシー | STINGS</title>
      <meta property="og:title" content="プライバシーポリシー | STINGS" />
      <meta name="twitter:title" content="プライバシーポリシー | STINGS"></meta>
    </Helmet>
    <div className="content">
      <section className="privacy-policy">
        <div className="privacy-policy__inner">
          <span className="mb">プライバシーポリシー</span>
          <span className="mb">最終更新日：2020年6月9日</span>
          <p className="mb">
            株式会社STINGS（以下｢当社｣といいます。）は、当社運営のサービスにおいて、皆様から個人情報（個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により、または他の情報と容易に照合することにより、特定の個人を識別することができるもの）をご提供いただく場合があります。当社は、ご提供いただいた個人情報を、以下に従ってお取り扱いいたします。また当社提供サービスの運営は、以下のポリシーに従って行います。
          </p>
          <p>
            ■ご提供いただいた個人情報は、あらかじめ皆様にお知らせした目的にのみ使用し、目的以外に使用する際には、事前にその目的についてお知らせいたします。
          </p>
          <p>
            ■ご提供いただいた個人情報の漏洩、滅失、毀損を防ぐため、必要かつ適切な安全管理措置を講じるとともにその改善に努めます。
          </p>
          <p>
            ■ご提供いただいた個人情報を、ご本人の同意なく第三者に開示いたしません（ただし、司法機関または行政機関から法的義務を伴う要請を受けた場合は、この限りではありません）。なお、皆様にお知らせした使用目的のために、第三者に対する開示が必要な場合は、当該第三者に対して、当該個人情報の厳重な管理を求め、目的以外の使用を行わせないようにいたします。
          </p>
          <p>
            ■ご提供いただいた個人情報の照会・修正・削除を希望される場合は、当該手続きのご依頼主（ご本人または法律の定めた代理人）の氏名と当該手続きの対象となる情報の全件を明示の上で、下記お問い合わせ窓口にご依頼下さい。速やかに対応させていただきます。また、手続きの過程で、ご依頼主のご本人確認をさせていただく場合がございますので、ご了承下さい。
          </p>
          <p>
            ■本プライバシーポリシーは、日本国の法律に従って解釈または適用されるものとします。また、本プライバシーポリシーに関する紛争については、その訴額に応じて東京簡易裁判所または東京地方裁判所を第一審の専属的合意管轄裁判所とします。
          </p>
          <p>
            ■当社は、細心の注意を払って、当社提供サービスに情報を掲載していますが、その妥当性や正確性などについて保証するものではありません。当社は、理由のいかんにかかわらず、当社提供サービスのご利用に関して生じたいかなる損害についても責任を負うものではありません
          </p>
          <p>
            ■当社は、予告なしに当社提供サービスの提供内容ならびにコンテンツを変更、または運営を中断、中止することがありますので、予めご了承下さい。当社は、理由のいかんにかかわらず、当社提供サービスの提供内容ならびにコンテンツの変更または運営の中断、中止によって生じたいかなる損害についても責任を負うものではありません。
          </p>
          <p>
            ■当社は、予告なしに当社提供サービスの提供内容ならびにコンテンツを変更、または運営を中断、中止することがありますので、予めご了承下さい。当社は、理由のいかんにかかわらず、当社提供サービスの提供内容ならびにコンテンツの変更または運営の中断、中止によって生じたいかなる損害についても責任を負うものではありません。
          </p>
        </div>
      </section>
    </div>
  </Layout>
);

export default PrivacyPolicyPage;
